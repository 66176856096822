import { useAuth0 } from '@auth0/auth0-react';

const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  return (path, method = 'GET', params = {}, body = null) => new Promise((resolve, reject) => {
    const run = async (localResolve, localReject) => {
      const accessToken = await getAccessTokenSilently();
      const searchParams = new URLSearchParams(params).toString();
      let url = `${process.env.REACT_APP_API_URI}${path}`;
      if (searchParams !== '') {
        url = `${url}?${searchParams}`;
      }

      fetch(url, {
        method,
        headers: {
          'Content-Type': method === 'PATCH' ? 'application/merge-patch+json' : 'application/ld+json',
          Accept: 'application/ld+json',
          authorization: `Bearer ${accessToken}`,
        },
        body: body === null ? null : JSON.stringify(body),
      })
        .then((response) => {
          if (!response.ok) {
            return Promise.reject();
          }

          return response.status === 204 ? {} : response.json();
        })
        .then((json) => {
          localResolve(json);
        })
        .catch((err) => {
          localReject(err);
        });
    };
    run(resolve, reject);
  });
};

export default useApi;
