export default {
  fontSize: 14,
  fontFamily: '"Roboto", Arial, sans-serif',
  h1: {
    fontWeight: 400,
    fontSize: '1.939rem',
  },
  h2: {
    fontWeight: 400,
    fontSize: '1.296rem',
    margin: '3rem 0 1.38rem',
  },
  h3: {
    fontWeight: 400,
    fontSize: '1.215rem',
    margin: '3rem 0 1.38rem',
  },
  h4: {
    fontWeight: 400,
    fontSize: '1.138rem',
    margin: '3rem 0 1.38rem',
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.067rem',
    margin: '3rem 0 1.38rem',
  },
  h6: {
    fontWeight: 400,
    fontSize: '0.937rem',
    margin: '3rem 0 1.38rem',
  }
};
