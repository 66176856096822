import React from 'react';
import { Hidden } from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import Link from 'components/Link';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

const styles = {
  link: {
    textDecoration: 'none',
    margin: (theme) => (theme.spacing(1, 1.5)),
    '&:hover': {
      color: (theme) => (theme.palette.secondary.main),
    }
  },
};

export default function () {
  const { t } = useTranslation(['components']);
  const { isAuthenticated } = useAuth0();

  return (
    <Hidden smDown>
      <nav>
        <Link variant="button" color="inherit" to="/" sx={styles.link} title={t('components:topbar.links.homepage.title', 'Accueil')}>
          <Hidden mdUp>
            <HomeIcon />
          </Hidden>
          <Hidden mdDown>
            {t('components:topbar.links.homepage.text', 'Accueil')}
          </Hidden>
        </Link>
        {isAuthenticated && (
          <Link variant="button" color="inherit" to="/dashboard" sx={styles.link} title={t('components:topbar.links.dashboard.title', 'Tableau de bord')}>
            <Hidden mdUp>
              <DashboardIcon />
            </Hidden>
            <Hidden mdDown>
              {t('components:topbar.links.dashboard.text', 'Tableau de bord')}
            </Hidden>
          </Link>
        )}
      </nav>
    </Hidden>
  );
}
