import React from 'react';
import { CircleLoader } from 'react-spinners';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
  text: {
    color: (theme) => (theme.palette.secondary.main),
  },
};

function AppBar() {
  const { t } = useTranslation(['components']);
  const theme = useTheme();

  return (
    <Box sx={styles.root}>
      <CircleLoader
        size={150}
        color={theme.palette.primary.main}
        loading
      />
      <Typography sx={styles.text} variant="h4">{t('components:pageLoader.text', 'Chargement…')}</Typography>
    </Box>
  );
}

export default AppBar;
