import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Moment from 'moment';
import Backend from 'i18next-http-backend';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';

const createI18n = () => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      ns: ['highcharts', 'themes', 'utils'],
      debug: false, // process.env.NODE_ENV !== 'production',
      fallbackLng: 'fr-FR',
      whitelist: ['fr-FR', 'en', 'es'],
      interpolation: {
        escapeValue: false,
      },
      backend: {
        // loadPath: '/locales/{{lng}}/{{ns}}.json',
        // allowMultiLoading: false,
        // crossDomain: false,
        // withCredentials: false,
        // overrideMimeType: false,
        referenceLng: 'fr-FR',
      },
      detection: {
        order: ['localStorage', 'navigator'],
        lookupLocalStorage: 'locale',
        checkWhitelist: true,
      },
    });
  i18n.on('languageChanged', (lng) => { Moment.locale(lng); });

  return i18n;
};
export default createI18n;
export { i18n };
