import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Collapse,
  ListItem
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

const styles = {
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: (theme) => (theme.palette.text.secondary),
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: (theme) => (theme.palette.text.secondary),
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: (theme) => (theme.typography.fontWeightRegular),
    '&.depth-0': {
      '& $title': {
        fontWeight: (theme) => (theme.typography.fontWeightMedium),
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 1,
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: (theme) => (theme.palette.secondary.main),
    '& $title': {
      fontWeight: (theme) => (theme.typography.fontWeightMedium),
    },
    '& $icon': {
      color: (theme) => (theme.palette.secondary.main),
    },
  },
};

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  ...rest
}) {
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        sx={styles.item}
        className={className}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          sx={styles.button}
          onClick={handleToggle}
          style={style}
        >
          {Icon && (
            <Icon
              sx={styles.icon}
              size="20"
            />
          )}
          <Box sx={styles.title}>
            {title}
          </Box>
          {open ? (
            <ExpandLessIcon
              size="small"
              color="inherit"
            />
          ) : (
            <ExpandMoreIcon
              size="small"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      sx={styles.itemLeaf}
      className={className}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        sx={styles.buttonLeaf}
        className={`depth-${depth}`}
        component={RouterLink}
        style={style}
        to={href}
      >
        {Icon && (
          <Icon
            sx={styles.icon}
            size="20"
          />
        )}
        <Box sx={styles.title}>
          {title}
        </Box>
        {Info && <Info sx={styles.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
