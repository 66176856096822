import {
  Description as DescriptionIcon
} from '@mui/icons-material';

const avatarsMap = {
  report: {
    icon: DescriptionIcon,
    className: 'avatarIndigo'
  }
};

export default avatarsMap;
