/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { responsiveFontSizes, createTheme as createMuiTheme } from '@mui/material';
import THEMES, { baseConfig } from 'theme/themes';

export function createTheme(settings = {}) {
  let themeConfig = THEMES.find((theme) => theme.id === settings.theme);
  if (!themeConfig) {
    [themeConfig] = THEMES;
  }

  return responsiveFontSizes(createMuiTheme(
    _.merge(
      {},
      baseConfig,
      themeConfig,
      { direction: settings.direction },
    ),
  ));
}
