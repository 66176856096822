import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  IconButton,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'hooks/useSettings';
import THEMES from 'theme/themes';
import { useTranslation } from 'react-i18next';

const styles = {
  popover: {
    minWidth: '200px',
    maxWidth: '320px',
    padding: 2,
  },
};

function Settings() {
  const { t } = useTranslation(['components']);
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState(settings);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('components:topbar.settings.tooltip.title', 'Paramètres')}>
        <IconButton color="inherit" onClick={handleOpen} ref={ref} size="large">
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{ sx: styles.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography>
          {t('components:topbar.settings.text', 'Paramètres')}
        </Typography>
        <Box mt={2}>
          <TextField
            fullWidth
            label={t('components:topbar.settings.input.theme.label', 'Thème')}
            name="theme"
            variant="standard"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
          >
            {THEMES.map((theme) => (
              <option
                key={theme.id}
                value={theme.id}
              >
                {theme.name}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            {t('components:topbar.settings.input.theme.save', 'Enregistrer')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Settings;
