import React, { useState, useRef } from 'react';
import {
  IconButton,
  Tooltip,
  Menu, MenuItem,
} from '@mui/material';
import { Translate as TranslateIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const styles = {
  popover: {
    maxWidth: '320px',
    padding: 1,
  },
};

export default function () {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { t, i18n } = useTranslation(['components']);

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('components:topbar.locale.tooltip.title', 'Langues')}>
        <IconButton
          size="small"
          color="inherit"
          onClick={handleOpen}
          ref={ref}
        >
          <TranslateIcon />
        </IconButton>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ sx: styles.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem selected={i18n.language === 'fr-FR'} onClick={() => changeLanguage('fr-FR')}>
          Français
        </MenuItem>
        <MenuItem selected={i18n.language === 'en'} onClick={() => changeLanguage('en')}>
          English
        </MenuItem>
        <MenuItem selected={i18n.language === 'es'} onClick={() => changeLanguage('es')}>
          Español
        </MenuItem>
      </Menu>
    </>
  );
}
