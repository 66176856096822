import React, { Fragment, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import DashboardLayout from 'layouts/Dashboard';
import ErrorLayout from 'layouts/Error';
import {
  AuthGuard,
  // SubscriberGuard
} from 'guards';
import MainLayout from 'layouts/MainLayout';

const routesConfig = [
  {
    key: 'app_root_main',
    path: '/',
    layout: MainLayout,
    element: lazy(() => import('views/pages/HomeView')),
  },
  {
    key: 'app_root_error',
    path: '/error',
    layout: ErrorLayout,
    routes: [
      {
        key: 'app_error',
        path: '/error/page-not-found',
        layout: ErrorLayout,
        element: lazy(() => import('views/errors/NotFound')),
      },
      {
        key: 'app_error_redirect',
        element: () => <Navigate to="/error/page-not-found" />,
      },
    ],
  },
  {
    key: 'app_root_dashboard',
    path: '/dashboard',
    layout: DashboardLayout,
    guard: AuthGuard,
    routes: [
      {
        key: 'app_dashboard',
        path: '/dashboard',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/pages/Dashboard')),
      },
      {
        key: 'app_dashboard_grand_angle_gavekal_four_quadrants',
        path: '/dashboard/grand-angle/gavekal-four-quadrants',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/GavekalFourQuadrants')),
      },
      {
        key: 'app_dashboard_grand_angle_gavekal_money_quadrants',
        path: '/dashboard/grand-angle/gavekal-money-quadrants',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/GavekalMoneyQuadrants')),
      },
      {
        key: 'app_dashboard_grand_angle_gavekal_new_quadrant',
        path: '/dashboard/grand-angle/gavekal-new-quadrant',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/Gavekal')),
      },
      {
        key: 'app_dashboard_grand_angle_price_dynamic',
        path: '/dashboard/grand-angle/price-dynamic',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/PriceDynamic')),
      },
      {
        key: 'app_dashboard_grand_angle_price_dynamic_id',
        path: '/dashboard/grand-angle/price-dynamic/:id',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/PriceDynamic/Symbol')),
      },
      {
        key: 'app_dashboard_indices_all',
        path: '/dashboard/indices',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/indices/All')),
      },
      {
        key: 'app_dashboard_indices_vix',
        path: '/dashboard/indices/vix',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/indices/VIX')),
      },
      {
        key: 'app_dashboard_indices_cpi',
        path: '/dashboard/indices/cpi',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/indices/CPI')),
      },
      {
        key: 'app_dashboard_indices_baa',
        path: '/dashboard/indices/baa',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/indices/Baa')),
      },
      {
        key: 'app_dashboard_indices_high-yield',
        path: '/dashboard/indices/high-yield',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/indices/HighYield')),
      },
      {
        key: 'app_dashboard_indices_rgdp',
        path: '/dashboard/indices/rgdp',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/indices/RGDP')),
      },
      {
        key: 'app_dashboard_indices_treasury-yield',
        path: '/dashboard/indices/treasury-yield',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/indices/TreasuryYield')),
      },
      {
        key: 'app_dashboard_grand_angle_ws',
        path: '/dashboard/grand-angle/ws',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/WicksellianSpread')),
      },
      {
        key: 'app_dashboard_indices_id',
        path: '/dashboard/indices/:id',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/indices/All/Symbol')),
      },
      {
        key: 'app_dashboard_exchange-rates',
        path: '/dashboard/exchange-rates',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/currencies/ExchangeRate')),
      },
      {
        key: 'app_dashboard_exchange-rates_symbol',
        path: '/dashboard/exchange-rates/:id',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/currencies/ExchangeRate/Symbol')),
      },
      {
        key: 'app_dashboard_crypto_currencies',
        path: '/dashboard/crypto-rates',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/currencies/CryptoExchangeRate')),
      },
      {
        key: 'app_dashboard_crypto_currency',
        path: '/dashboard/crypto-rates/:id',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/currencies/CryptoExchangeRate/Symbol')),
      },
      {
        key: 'app_dashboard_commodities_all',
        path: '/dashboard/commodities',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/commodities')),
      },
      {
        key: 'app_dashboard_commodities_id',
        path: '/dashboard/commodities/:id',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/commodities/Symbol')),
      },
      {
        key: 'app_dashboard_grand_angle_sp500-gold',
        path: '/dashboard/grand-angle/sp500-gold',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/SP500GoldRatio')),
      },
      {
        key: 'app_dashboard_grand_angle_gold-sp500',
        path: '/dashboard/grand-angle/gold-sp500',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/GoldSP500Ratio')),
      },
      {
        key: 'app_dashboard_grand_angle_ppp',
        path: '/dashboard/grand-angle/ppp',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/PPP')),
      },
      {
        key: 'app_dashboard_grand_angle_mason',
        path: '/dashboard/grand-angle/mason',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/Mason')),
      },
      {
        key: 'app_dashboard_grand_angle_mason_id',
        path: '/dashboard/grand-angle/mason/:id',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/Mason/Symbol')),
      },
      {
        key: 'app_dashboard_grand_angle_brent-sp500',
        path: '/dashboard/grand-angle/brent-sp500',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/GrandAngle/BrentSP500Ratio')),
      },
      {
        key: 'app_dashboard_redirect',
        element: () => <Navigate to="/error/page-not-found" />,
      },
    ],
  },
  {
    key: 'app_root_wallets',
    path: '/portfolios',
    layout: DashboardLayout,
    guard: AuthGuard,
    routes: [
      {
        key: 'app_wallets',
        path: '/portfolios',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/portfolios/Portfolios')),
      },
      {
        key: 'app_wallets_id',
        path: '/portfolios/:id',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/portfolios/Portfolio')),
      },
      {
        key: 'app_wallets_id_shares_entry',
        path: '/portfolios/:id/shares/:entry',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/portfolios/ShareEntry')),
      },
      {
        key: 'app_wallets_id_currencies_entry',
        path: '/portfolios/:id/currencies/:entry',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/portfolios/CurrencyEntry')),
      },
      {
        key: 'app_wallets_id_manual_entry',
        path: '/portfolios/:id/manuals/:entry',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/portfolios/ManualEntry')),
      },
      {
        key: 'app_wallets_id_cryptos_entry',
        path: '/portfolios/:id/cryptos/:entry',
        layout: DashboardLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/portfolios/CryptoEntry')),
      },
      {
        key: 'app_wallets_redirect',
        element: () => <Navigate to="/error/page-not-found" />,
      },
    ],
  },
  {
    key: 'app_root_usr',
    path: '/user',
    layout: MainLayout,
    guard: AuthGuard,
    routes: [
      {
        key: 'app_user_account',
        path: '/user/account',
        layout: MainLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/user/Profile')),
      },
      {
        key: 'app_user_notifications',
        path: '/user/notifications',
        layout: MainLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/user/Notifications')),
      },
      {
        key: 'app_user_alerts',
        path: '/user/alerts',
        layout: MainLayout,
        guard: AuthGuard,
        element: lazy(() => import('views/user/Alerts')),
      },
      {
        key: 'app_user_redirect',
        element: () => <Navigate to="/error/page-not-found" />,
      },
    ],
  },
  {
    key: 'app_root_all',
    route: '*',
    layout: MainLayout,
    routes: [
      {
        key: 'app_*_terms-of-use',
        path: '/terms-of-use',
        layout: MainLayout,
        element: lazy(() => import('views/pages/TermsOfUse')),
      },
      {
        key: 'app_*_privacy',
        path: '/privacy',
        layout: MainLayout,
        element: lazy(() => import('views/pages/Privacy')),
      },
      {
        key: 'app_*_redirect',
        element: () => <Navigate to="/error/page-not-found" />,
      },
    ],
  },
];

const renderRoutes = (routes) => (routes ? (
  routes.map((route) => {
    const Guard = route.guard || Fragment;
    const Layout = route.layout || Fragment;
    const Element = route.element || <>...</>;

    if (route.routes) {
      return renderRoutes(route.routes);
    }

    return (
      <Route
        key={route.key}
        path={route.path}
        exact={route.exact}
        element={(
          <Guard>
            <Layout>
              <Element />
            </Layout>
          </Guard>
          )}
      />
    );
  })
) : null);

function RouteTree() {
  return <Suspense fallback={<LinearProgress />}><Routes>{renderRoutes(routesConfig)}</Routes></Suspense>;
}

export default RouteTree;
