import React from 'react';
import PropTypes from 'prop-types';
import PageLoader from 'components/PageLoader';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function AuthGuard({ children }) {
  const {
    isLoading, isAuthenticated, user, logout
  } = useAuth0();

  if (isLoading) {
    return <PageLoader />;
  }
  if (!isAuthenticated) {
    return (<Navigate to="/" />);
  }
  if (user.blocked === true) {
    logout();
  }
  if (user['https://security.neystor.com/subscriptions']) {
    const subscription = user['https://security.neystor.com/subscriptions'].find((s) => s.name === 'neystor');
    if (subscription && subscription.exp && subscription.exp < (new Date()).getTime() / 1000) {
      logout();
    }
  }

  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any,
};

export default AuthGuard;
