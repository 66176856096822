import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Toolbar,
  Hidden,
  IconButton,
  SvgIcon,
  Typography,
  ButtonGroup,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu as MenuIcon } from 'react-feather';
import Locales from './Locales';
import Settings from './Settings';
import Account from './Account';
import Notifications from './Notifications';
import Links from './Links';

const styles = {
  root: {
    zIndex: (theme) => (theme.zIndex.drawer + 100),
  },
  toolbar: {
    minHeight: '64px',
  },
};

function TopBar({
  onMobileNavOpen,
  ...rest
}) {
  const { isAuthenticated } = useAuth0();

  return (
    <AppBar
      sx={styles.root}
      {...rest}
    >
      <Toolbar sx={styles.toolbar}>
        <Hidden lgUp>
          {isAuthenticated && (
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
            size="large"
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          )}
        </Hidden>
        <Typography variant="h1" color="inherit">
          {process.env.REACT_APP_NAME}
        </Typography>
        <Box ml={2} flexGrow={1} />
        <Links />
        <Locales />
        <Settings />
        {isAuthenticated && <Notifications />}
        <Box ml={2}>
          <ButtonGroup variant="text" color="inherit" size="medium">
            <Account />
          </ButtonGroup>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
