import React from 'react';
import {
  Avatar,
  Box, Button,
  List, ListItem, ListItemAvatar, ListItemText, Typography, colors
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Link from 'components/Link';
import useApi from 'hooks/Api';
import avatarsMap from 'utils/notifications';

const styles = {
  icon: {
    backgroundColor: (theme) => (theme.palette.secondary.main),
    color: (theme) => (theme.palette.secondary.contrastText),
  },
  avatar: {
    color: colors.common.white
  },
  avatarBlue: {
    backgroundColor: colors.blue[500]
  },
  avatarGreen: {
    backgroundColor: colors.green[500]
  },
  avatarOrange: {
    backgroundColor: colors.orange[500]
  },
  avatarIndigo: {
    backgroundColor: colors.indigo[500]
  }
};

function NotificationList({ notifications }) {
  const callApi = useApi();
  const { t } = useTranslation(['components']);
  const handleMarkAllAsRead = () => {
    callApi('/notifications/mark-all-as-read', 'PUT');
  };

  if (notifications.length === 0) {
    return (
      <Box p={2}>
        <Typography variant="body2" color="textPrimary">
          {t('components:topbar.notificationList.menu.no_notifications', 'Aucune notification')}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <List disablePadding>
        {notifications.map((notification) => {
          const avatar = avatarsMap[notification.type ?? 'report'];

          return (
            <ListItem
              to="/user/notifications"
              component={Link}
              divider
              key={notification.id}
            >
              <ListItemAvatar>
                <Avatar
                  sx={styles.icon}
                >
                  <Avatar sx={styles.avatar} className={avatar.className}>
                    <avatar.icon />
                  </Avatar>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={notification.title}
                primaryTypographyProps={{ variant: 'subtitle2', color: 'textPrimary' }}
                secondary={notification.subtitle}
              />
            </ListItem>
          );
        })}
      </List>
      <Box
        p={1}
        display="flex"
        justifyContent="center"
      >
        <Button size="small" onClick={handleMarkAllAsRead}>
          {t('components:topbar.notificationList.menu.mark_all_as_read', 'Marquer comme lu...')}
        </Button>
      </Box>
    </>
  );
}

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(Object),
};

export default NotificationList;
