import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import Theme from 'utils/Theme';
import AppSnackBarProvider from 'components/SnackBarProvider';
import Auth0 from 'utils/Auth0';
import { SettingsProvider } from 'contexts/SettingsContext';
import { restoreSettings } from 'utils/settings';
import createI18n from 'utils/i18n';
import '@fontsource/roboto';
import * as serviceWorker from './serviceWorker';
import App from './App';

const settings = restoreSettings();
createI18n();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <SettingsProvider settings={settings}>
      <Theme>
        <Suspense fallback={null}>
          <AppSnackBarProvider>
            <Auth0>
              <App />
            </Auth0>
          </AppSnackBarProvider>
        </Suspense>
      </Theme>
    </SettingsProvider>
  </StrictMode>
);

serviceWorker.unregister();
