import React from 'react';
import PropTypes from 'prop-types';
import {
  useScrollTrigger,
  Fab,
  Zoom,
  Box
} from '@mui/material';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@mui/icons-material';

const styles = {
  root: {
    position: 'fixed',
    bottom: (theme) => (theme.spacing(2)),
    left: (theme) => (theme.spacing(2)),
  },
};

function ScrollTop({ element, children }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    element.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={styles.root}>
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

function ScrollToTop(props) {
  return (
    <ScrollTop {...props}>
      <Fab color="secondary" size="small" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollTop>
  );
}

export default ScrollToTop;
