import { assign, Machine } from 'xstate';

export default Machine({
  id: 'fetch',
  initial: 'idle',
  context: null,
  states: {
    idle: {
      on: {
        LOAD: 'loading'
      }
    },
    loading: {
      invoke: {
        id: 'doFetch',
        src: 'fetchData',
        onDone: {
          target: 'success',
          actions: assign((_, event) => ({ data: event.data })),
        },
        onError: {
          target: 'failure'
        },
      }
    },
    success: {
      on: {
        LOAD: 'loading'
      }
    },
    failure: {
      on: {
        LOAD: 'loading'
      }
    }
  }
});
