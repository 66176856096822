import React, {
  useRef,
  useState,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Divider,
  ListItemIcon,
} from '@mui/material';
import {
  AccountCircle as AccountCircleIcon,
  ExitToApp as ExitToAppIcon,
  Announcement as AnnouncementIcon,
  NotificationsActive as NotificationsActiveIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

const styles = {
  avatar: {
    height: '32px',
    width: '32px',
    marginLeft: 1,
    marginRight: 1,
  },
  popover: {
    width: '250px',
  },
};

function Account({ ...rest }) {
  const ref = useRef(null);
  const { user, logout, loginWithRedirect } = useAuth0();
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation(['components']);

  const handleLogout = () => {
    setOpen(false);
    window.$crisp.push(['do', 'session:reset']);
    logout({ returnTo: document.location.origin });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!user) {
    return (
      <>
        <Hidden mdDown>
          <Button
            title={t('components:topbar.menu.account.login.title', 'Se connecter')}
            {...rest}
            onClick={async () => {
              await loginWithRedirect();
            }}
            startIcon={<AccountCircleIcon />}
          >
            {t('components:topbar.menu.account.login.text', 'Se connecter')}
          </Button>
        </Hidden>
        <Hidden mdUp>
          <Tooltip title={t('components:topbar.menu.account.login.title', 'Se connecter')}>
            <IconButton
              color="inherit"
              {...rest}
              onClick={async () => {
                await loginWithRedirect();
              }}
              size="large"
            >
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
      </>
    );
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt={t('components:topbar.avatar.alt', 'Utilisateur')}
          sx={styles.avatar}
          src={user.picture}
        />
        <Hidden mdDown>
          <Typography>
            {user.name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ sx: styles.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          to="/user/account"
        >
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          {t('components:topbar.menu.account.menuItem.account', 'Mon compte')}
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/user/notifications"
        >
          <ListItemIcon>
            <NotificationsActiveIcon fontSize="small" />
          </ListItemIcon>
          {t('components:topbar.menu.account.menuItem.notifications', 'Notifications')}
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={false}
          component={RouterLink}
          to="/user/alerts"
        >
          <ListItemIcon>
            <AnnouncementIcon fontSize="small" />
          </ListItemIcon>
          {t('components:topbar.menu.account.menuItem.alerts', 'Alertes')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          {t('components:topbar.menu.account.menuItem.logout', 'Déconnecter')}
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
