import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import NavBar from 'components/NavBar';
import Topbar from 'components/Topbar';
import useClasses from 'hooks/Style/useClasses';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 56,
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 56,
    },
  },
});

function Dashboard({ children }) {
  const classes = useClasses(styles);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <Topbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {children}
          </Suspense>
        </div>
      </div>
    </>
  );
}

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Dashboard;
