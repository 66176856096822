import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as MUILink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Link({ forwardedRef, children, ...rest }) {
  return (
    <MUILink color="inherit" to="/" component={RouterLink} {...rest} ref={forwardedRef}>
      {children}
    </MUILink>
  );
}

Link.propTypes = {
  forwardedRef: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default forwardRef((props, ref) => <Link {...props} forwardedRef={ref} />);
