import React, {
  useState, useRef, useEffect, useCallback
} from 'react';
import {
  Badge,
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Bell as BellIcon
} from 'react-feather';
import { useMachine } from '@xstate/react';
import SimpleFetchStateMachine from 'machines/SimpleFetchStateMachine';
import useApi from 'hooks/Api';
import NotificationList from './NotificationList';

const styles = {
  popover: {
    minWidth: '200px',
    maxWidth: '420px',
  },
};

export default function () {
  const callApi = useApi();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation(['components']);
  const [state, send] = useMachine(SimpleFetchStateMachine, {
    services: {
      fetchData: () => callApi('/notifications', 'GET', {
        'exists[readAt]': false,
        'order[createdAt]': 'desc'
      })
    }
  });
  const sendCallback = useCallback(send, []);

  useEffect(() => {
    sendCallback('LOAD');
  }, [sendCallback]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let badgeParams = { invisible: true };
  if (state.matches('success')) {
    badgeParams = {
      badgeContent: state.context.data['hydra:member'].length
    };
  }

  return (
    <>
      <Tooltip title={t('components:topbar.notifications.tooltip.title', 'Notifications')}>
        <Badge color="secondary" variant="dot" {...badgeParams}>
          <IconButton
            size="small"
            color="inherit"
            onClick={handleOpen}
            ref={ref}
          >
            <BellIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        PaperProps={{ sx: styles.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography>
            {t('components:topbar.notifications.menu.header', 'Notifications')}
          </Typography>
        </Box>
        {(state.matches('idle') || state.matches('loading')) && (
          <Box p={2}>
            <Typography>
              {t('components:topbar.notifications.menu.loading', 'Chargement...')}
            </Typography>
          </Box>
        )}
        {(state.matches('failure')) && (
          <Box p={2}>
            <Typography>
              {t('components:topbar.notifications.menu.failure', 'Erreur de chargement')}
            </Typography>
          </Box>
        )}
        {(state.matches('success')) && <NotificationList notifications={state.context.data['hydra:member']} />}
      </Popover>
    </>
  );
}
