import React, {
  createContext, useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { storeSettings } from 'utils/settings';

const SettingsContext = createContext();
const matched = window.matchMedia('(prefers-color-scheme: dark)').matches;
const themeId = matched === true ? 'DARK' : 'LIGHT';

const defaultSettings = {
  theme: themeId,
};

export function SettingsProvider({ settings, children }) {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (updatedSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, updatedSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };
  const settingsValue = useMemo(() => ({
    settings: currentSettings,
    saveSettings: handleSaveSettings,
  }), [handleSaveSettings, currentSettings]);

  return (
    <SettingsContext.Provider
      value={settingsValue}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
