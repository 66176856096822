import React, { createRef } from 'react';
import { Button } from '@mui/material';
import { SnackbarProvider as NativeSnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SnackBarProvider({ children }) {
  const { t } = useTranslation(['components']);
  const notistackRef = createRef();
  const onDismissClicked = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const actionButton = (key) => (
    <Button
      onClick={onDismissClicked(key)}
      size="small"
      color="secondary"
      aria-label={t('components:snckbarProvider.button.dismiss.aria', 'OK')}
      title={t('components:snckbarProvider.button.dismiss.title', 'OK')}
    >
      {t('components:snckbarProvider.button.dismiss.text', 'OK')}
    </Button>
  );
  return (
    <NativeSnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      action={actionButton}
    >
      {children}
    </NativeSnackbarProvider>
  );
}

SnackBarProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SnackBarProvider;
