import React from 'react';
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Storefront as StorefrontIcon,
  LocalAtm as LocalAtmIcon,
  Stars as StarsIcon,
  BubbleChart as BubbleChartIcon,
  Gavel as GavelIcon,
  Dashboard as DashboardIcon,
  VisibilityOff as VisibilityOffIcon,
  Bookmarks as BookmarksIcon,
  // SystemUpdateAlt as SystemUpdateAltIcon,
} from '@mui/icons-material';
import { i18n } from 'utils/i18n';
import { Badge } from '@mui/material';

const smallNavigation = [];
const commonPagesNavigation = [];
const navigation = [];

function fill() {
  smallNavigation.length = 0;
  smallNavigation.push({
    subheader: i18n.t('utils:navigation.pages', 'Pages'),
    items: [
      {
        title: i18n.t('utils:navigation.dashboard', 'Tableau de bord'),
        href: '/dashboard',
        icon: DashboardIcon,
      },
    ],
  });

  commonPagesNavigation.length = 0;
  commonPagesNavigation.push({
    subheader: i18n.t('utils:navigation.information', 'Informations'),
    items: [
      {
        title: i18n.t('utils:navigation.terms-of-use', 'Conditions d’utilisation'),
        href: '/terms-of-use',
        icon: GavelIcon,
      },
      {
        title: i18n.t('utils:navigation.privacy', 'Politique de confidentialité'),
        href: '/privacy',
        icon: VisibilityOffIcon,
      },
    ],
  });

  navigation.length = 0;
  navigation.push(
    {
      subheader: i18n.t('utils:navigation.portfolios', 'Portefeuilles'),
      items: [
        {
          title: i18n.t('utils:navigation.all-portfolios', 'Mes portefeuilles'),
          href: '/portfolios',
          icon: AccountBalanceWalletIcon,
        },
      ],
    },
    {
      subheader: i18n.t('utils:navigation.financial-tools', 'Outils financiers'),
      items: [
        {
          title: i18n.t('utils:navigation.grand-angle', 'Grand Angle'),
          icon: StarsIcon,
          href: '/dashboard/grand-angle',
          items: [
            {
              title: i18n.t('utils:navigation.gavekalFourQuadrants', 'Quadrant Gavekal Économique'),
              href: '/dashboard/grand-angle/gavekal-four-quadrants',
            },
            {
              title: (
                <Badge color="secondary" variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} invisible={new Date(2021, 11, 30) < new Date()}>
                  {i18n.t('utils:navigation.gavekalMoneyQuadrants', 'Quadrant Gavekal Monétaire')}
                </Badge>
              ),
              href: '/dashboard/grand-angle/gavekal-money-quadrants',
            },
            {
              title: i18n.t('utils:navigation.gavekalNewQuadrant', 'Quadrant Gavekal Financier'),
              href: '/dashboard/grand-angle/gavekal-new-quadrant',
            },
            {
              title: i18n.t('utils:navigation.masons-sentiment', 'Méthodes des maçons'),
              href: '/dashboard/grand-angle/mason',
            },
            {
              title: i18n.t('utils:navigation.priceDynamic', 'Dynamique des prix'),
              href: '/dashboard/grand-angle/price-dynamic',
            },
            {
              href: '/dashboard/grand-angle/ppp',
              title: i18n.t('utils:navigation.ppp-exch-rate', 'PPA / Tx. de change'),
            },
            {
              href: '/dashboard/grand-angle/ws',
              title: i18n.t('utils:navigation.wicksellian-spread', 'Spread Wicksellien'),
            },
          ],
        },
        {
          title: i18n.t('utils:navigation.indices', 'Les indices'),
          icon: BubbleChartIcon,
          href: '/dashboard/indices',
          items: [
            {
              href: '/dashboard/indices',
              title: i18n.t('utils:navigation.all-indices', 'Tous les indices'),
            },
            {
              href: '/dashboard/indices/vix',
              title: i18n.t('utils:navigation.vix', 'VIX'),
            },
          ],
        },
        {
          title: i18n.t('utils:navigation.commodities', 'Matières premières'),
          icon: StorefrontIcon,
          href: '/dashboard/commodities',
          items: [
            {
              href: '/dashboard/commodities',
              title: i18n.t('utils:navigation.all-commodities', 'Toutes les matières premières'),
            },
            {
              href: '/dashboard/grand-angle/brent-sp500',
              title: i18n.t('utils:navigation.brent-sp500', 'Ratio Brent / S&P500'),
            },
            {
              href: '/dashboard/grand-angle/sp500-gold',
              title: i18n.t('utils:navigation.sp500-gold', 'Ratio S&P500 / Or'),
            },
            {
              href: '/dashboard/grand-angle/gold-sp500',
              title: i18n.t('utils:navigation.gold-sp500', 'Ratio 10kg Or / S&P500'),
            },
          ],
        },
        {
          title: i18n.t('utils:navigation.currencies', 'Devises'),
          icon: LocalAtmIcon,
          href: '/dashboard/currencies',
          items: [
            {
              href: '/dashboard/exchange-rates',
              title: i18n.t('utils:navigation.exchange-rates', 'Taux de change'),
            },
            {
              href: '/dashboard/crypto-rates',
              title: i18n.t('utils:navigation.crypto-rates', 'Crypto-devises'),
            },
          ],
        },
        {
          title: i18n.t('utils:navigation.other', 'Autres'),
          icon: BookmarksIcon,
          href: '/dashboard/other',
          items: [
            {
              href: '/dashboard/indices/cpi',
              title: i18n.t('utils:navigation.cpi', 'Prix à la consommation'),
            },
            {
              href: '/dashboard/indices/baa',
              title: i18n.t('utils:navigation.baa', 'BAA'),
            },
            {
              href: '/dashboard/indices/high-yield',
              title: i18n.t('utils:navigation.high-yield', 'High Yield'),
            },
            {
              href: '/dashboard/indices/rgdp',
              title: i18n.t('utils:navigation.usa-rgdp', 'PIB (USA)'),
            },
            {
              href: '/dashboard/indices/treasury-yield',
              title: i18n.t('utils:navigation.treasury-yield', 'Bons du trésor'),
            },
          ],
        },
      ],
    },
  );
}

fill();
i18n.on('languageChanged loaded init', () => {
  fill();
});

export {
  smallNavigation,
  commonPagesNavigation,
};
export default navigation;
