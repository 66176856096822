import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  Typography,
  Button,
} from '@mui/material';
import navConfig, { smallNavigation, commonPagesNavigation } from 'utils/navigation';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import renderNavItems from './helpers';

const styles = {
  mobileDrawer: {
    width: '256px',
    top: '64px',
    height: 'calc(100% - 64px)',
  },
  desktopDrawer: {
    width: '256px',
    top: '64px',
    height: 'calc(100% - 64px)',
  },
  avatar: {
    width: '64px',
    height: '64px',
  },
};

function NavBar({ openMobile, onMobileClose }) {
  const { t } = useTranslation(['components']);
  const location = useLocation();
  const { user, logout } = useAuth0();
  const handleLogout = () => {
    window.$crisp.push(['do', 'session:reset']);
    logout();
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (!user) {
    return null;
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <Avatar
              alt={t('components:navBar.avatar.alt', 'Utilisateur')}
              sx={styles.avatar}
              src={user.picture}
            />
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Typography variant="h5" color="textPrimary">
              {user.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.email}
            </Typography>
          </Box>
          <Hidden smUp>
            <Box
              p={2}
              display="flex"
              justifyContent="center"
            >
              <Button
                size="small"
                variant="outlined"
                onClick={handleLogout}
              >
                {t('components:navBar.button.logout', 'Déconnecter')}
              </Button>
            </Box>
          </Hidden>
        </Box>
        <Divider />
        <Box p={2}>
          {smallNavigation.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
                )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
          {commonPagesNavigation.map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          PaperProps={{ sx: styles.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          PaperProps={{ sx: styles.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
