import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from '@mui/material';

function OutgoingLink({ forwardedRef, children, ...rest }) {
  return (
    <Link target="_blank" rel="nofollow noreferrer noopener" ref={forwardedRef} {...rest}>
      {children}
    </Link>
  );
}

OutgoingLink.propTypes = {
  children: PropTypes.node.isRequired,
  forwardedRef: PropTypes.func,
};

export default forwardRef((props, ref) => <OutgoingLink {...props} forwardedRef={ref} />);
