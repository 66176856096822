import React from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import {
  Language as LanguageIcon,
  YouTube as YouTubeIcon,
  Gavel as GavelIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import Link from 'components/Link';
import OutgoingLink from 'components/OutgoingLink';
import { useTranslation } from 'react-i18next';
import useClasses from 'hooks/Style/useClasses';

const styles = (theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  linkIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center'
  },
});

function Footer() {
  const classes = useClasses(styles);
  const { t } = useTranslation(['components']);

  return (
    <Container maxWidth="lg" component="footer" className={classes.footer}>
      <Grid container spacing={4} justifyContent="space-evenly">
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            {process.env.REACT_APP_NAME}
          </Typography>
          <ul className={classes.ul}>
            <li>
              <Link to="/terms-of-use" variant="subtitle1" color="textSecondary">
                <GavelIcon />
                &nbsp;
                {t('components:footer.link.terms-of-use.text', 'Conditions d’utilisation')}
              </Link>
            </li>
            <li>
              <Link to="/privacy" variant="subtitle1" color="textSecondary">
                <VisibilityOffIcon />
                &nbsp;
                {t('components:footer.link.privacy.text', 'Politique de confidentialité')}
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            {t('components:footer.link.company-name.text', 'Grand Angle')}
          </Typography>
          <ul className={classes.ul}>
            <li>
              <OutgoingLink href={t('components:footer.link.website.url', '')} variant="subtitle1" color="textSecondary" className={classes.linkIcon}>
                <LanguageIcon />
                &nbsp;
                {t('components:footer.link.website.text', 'Le site')}
              </OutgoingLink>
            </li>
            <li>
              <OutgoingLink href={t('components:footer.link.youtube-channel.url', '')} variant="subtitle1" color="textSecondary" className={classes.linkIcon}>
                <YouTubeIcon />
                &nbsp;
                {t('components:footer.link.youtube-channel.text', 'La chaine')}
              </OutgoingLink>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Typography variant="body2" color="textSecondary" align="center">
          {t('components:footer.copyright', 'Grand Angle, tous droits réservés · {{year}}', { year: new Date().getFullYear() })}
        </Typography>
      </Box>
    </Container>
  );
}

export default Footer;
