import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden } from '@mui/material';
import Topbar from 'components/Topbar';
import Footer from 'components/Footer';
import NavBar from 'components/NavBar';
import ScrollToTop from 'components/ScrollToTop';

const styles = {
  root: {
    // backgroundColor: theme.palette.background.paper,
    height: '100%',
    width: '100%',
  },
  wrapper: {
    paddingTop: '64px',
  },
};

function MainLayout({ children }) {
  const topbarRef = useRef(null);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <Box sx={styles.root}>
      <div id="topbar" ref={topbarRef}>
        <Topbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      </div>
      <Hidden lgUp>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </Hidden>
      <Box sx={styles.wrapper}>
        {children}
      </Box>
      <ScrollToTop element={topbarRef} />
      <Footer />
    </Box>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
};

export default MainLayout;
