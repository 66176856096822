import React from 'react';
import PageLoader from 'components/PageLoader';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useAuth0 } from '@auth0/auth0-react';
import Routes from 'utils/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import history from './utils/history';
import useClasses from './hooks/Style/useClasses';

const styles = (theme) => ({
  '@global': {
    '.highcharts-title': {
      fontFamily: theme.typography.h4.fontFamily,
      fill: theme.palette.text.primary,
      fontWeight: theme.typography.h4.fontWeight,
      letterSpacing: theme.typography.h4.letterSpacing,
      fontSize: theme.typography.h4.fontSize,
    },
    '.highcharts-subtitle': {
      fontFamily: theme.typography.h5.fontFamily,
      fill: theme.palette.text.primary,
      fontWeight: theme.typography.h5.fontWeight,
      letterSpacing: theme.typography.h5.letterSpacing,
      fontSize: theme.typography.h5.fontSize,
    },
    '.highcharts-label': {
      fontFamily: theme.typography.fontFamily,
      fill: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeight,
      letterSpacing: theme.typography.letterSpacing,
      fontSize: theme.typography.fontSize,
    }
  }
});

function App() {
  const { isLoading } = useAuth0();
  useClasses(styles);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

export default App;
