import { List } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
// import { matchPath } from 'react-router-dom';
import NavItem from './NavItem';

function reduceChildRoutes({
  acc, pathname, item, depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    /* const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    }); */
    const open = false;

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({ // eslint-disable-line no-use-before-define
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />,
    );
  }

  return acc;
}

export default function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  );
}

renderNavItems.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired
};
