import React from 'react';
import PropTypes from 'prop-types';
import PageLoader from 'components/PageLoader';
import { Navigate, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function AuthGuard({ children }) {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <PageLoader />;
  }
  if (isAuthenticated) {
    return (<Route render={() => <Navigate to="/dashboard" />} />);
  }
  return children;
}

AuthGuard.propTypes = {
  children: PropTypes.any,
};

export default AuthGuard;
