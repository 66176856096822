import { i18n } from 'utils/i18n';
import typography from 'theme/typography';
import i18next from 'i18next';
import {
  grey,
  blue,
  orange,
  common,
} from '@mui/material/colors';

const baseConfig = {
  typography,
  components: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'bold' },
          style: {
            fontSize: '10px',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: 'normal !important',
          },
        }
      ],
    }
  },
};

const lightTheme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#0d75b9',
    },
    secondary: orange,
    divider: blue[200],
    background: {
      default: grey[50],
      paper: common.white,
    },
    text: {
      primary: grey[900],
      secondary: grey[800],
    },
  },
};
const darkTheme = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#0d75b9',
    },
    secondary: orange,
    divider: blue[700],
    background: {
      default: grey[900],
      paper: grey[900],
    },
    text: {
      primary: '#fff',
      secondary: grey[500],
    },
  },
};

const themes = [];

function fill() {
  themes.length = 0;
  themes.push({ id: 'LIGHT', name: i18n.t('themes:light', 'Clair'), ...lightTheme });
  themes.push({ id: 'DARK', name: i18n.t('themes:dark', 'Sombre'), ...darkTheme });
}

fill();
i18next.on('languageChanged loaded init', () => {
  fill();
});

export default themes;
export { baseConfig };
