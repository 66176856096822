import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import PropTypes from 'prop-types';
import useSettings from 'hooks/useSettings';
import { createTheme } from 'theme';
import { StyledEngineProvider, CssBaseline } from '@mui/material';
import createCache from '@emotion/cache';
import { CacheProvider, ThemeProvider } from '@emotion/react';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

function Theme({ children }) {
  const { settings } = useSettings();
  const theme = createTheme(settings);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CacheProvider value={muiCache}>
            <CssBaseline />
            {children}
          </CacheProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
