import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@mui/material';

const styles = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0, // IE11 fix
    },
  },
  content: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
  },
};

function Error({ children }) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <Suspense fallback={<LinearProgress />}>
          {children}
        </Suspense>
      </Box>
    </Box>
  );
}

Error.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Error;
